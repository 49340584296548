import { login, logout, get_user_info } from '../../api/auth'
import { Commit } from 'vuex';
import { get_token, set_token, remove_token } from '../../utils/auth'

export const state = {
    token: get_token(),
    user: localStorage.getItem("user"),
    role_list: JSON.parse(localStorage.getItem("role_list") || "{}"),
    disableLoginStore: false,
};

export const actions = {
    UserLogin({commit}: { commit: Commit }, login_form: any ) {
        return new Promise((resolve, reject) => {
            login(login_form).then((response: any) => {
                const data = response.data;
                commit('SET_USER', data.name);
                commit('SET_TOKEN', data.token);
                set_token(data.token);
                resolve("登入成功");
            }).catch(error => {
                if (error?.response?.data?.disableLoginStore) {
                    commit('SET_DISABLE_LOGIN_STORE', error?.response?.data?.disableLoginStore);
                } else {
                    commit('SET_DISABLE_LOGIN_STORE', false);
                }
                if (error.message === 'Request failed with status code 401') {
                    reject("登入失敗，帳號或密碼錯誤");
                } else if (error.message === 'Request failed with status code 402') {
                    reject("登入失敗，帳號停用中");
                } else if (error.message === 'Request failed with status code 422') {
                    reject("登入失敗，" + error.response.data.message);
                } else {
                    reject("登入失敗，異常錯誤");
                }
            });
        });
    },
    UserLogout({commit}: { commit: Commit } ) {
        return new Promise((resolve, reject) => {
            logout().then((response: any) => {
                commit('SET_TOKEN', null);
                remove_token();
                localStorage.removeItem("user");
                localStorage.removeItem("role_list");
                resolve("登出成功");
            }).catch(error => {
                remove_token();
                localStorage.removeItem("user");
                localStorage.removeItem("role_list");
                if (error.message === 'Request failed with status code 401') {
                    reject("登出失敗，使用者尚未登入");
                } else {
                    reject("登出失敗，異常錯誤");
                }
            });
        });
    },
    GetUserInfo({commit}: { commit: Commit }) {
        return new Promise((resolve, reject) => {
            get_user_info().then((response: any) => {
                const data = response.data;
                commit('SET_USER', data.name);
                commit('SET_ROLE_LIST', data.role_list);
                localStorage.setItem("user", data.name);
                localStorage.setItem("role_list", JSON.stringify(data.role_list));
                resolve(response);
            }).catch(error => {
                reject("異常錯誤");
            });
        });
    }
};

export const mutations = {
    SET_BEARERTOKEN: (state: any, bearer: any) => {
        state.bearer = bearer;
    },
    SET_USER: (state: any, user: any) => {
        state.user = user;
    },
    SET_EMAIL: (state: any, email: any) => {
        state.email = email;
    },
    SET_TOKEN: (state: any, token: any) => {
        state.token = token;
    },
    SET_ROLE_LIST: (state: any, role_list: any) => {
        state.role_list = role_list;
    },
    SET_DISABLE_LOGIN_STORE: (state: any, disableLoginStore: any) => {
        state.disableLoginStore = disableLoginStore;
    },
};

export const getters = {
    // isAuthenticated: (state: any) => !!state.user || !!sessionStorage.getItem("user"),
};

export default {
    state,
    actions,
    mutations,
    // getters,
    namespaced: true,
};
