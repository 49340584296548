
import { useRouter } from 'vue-router';
import { onUnmounted, reactive } from 'vue';
import { get_token } from './utils/auth';

export default ({
    setup() {
        const router = useRouter();
        // 監聽瀏覽器返回事件
        if (window.history && (window.history.pushState)) {
            history.pushState(null, '', document.URL);
        }
        const unwatch = router.beforeEach((to, from, next) => {
            if (to.path == '/Login'
                || to.path == '/login'
                || to.path == '/Register'
                || to.path == '/ForgetPassword'
                || to.path == '/ResendVerificationMail') {
                if (!get_token()) {
                    next();
                } else {
                    next({ path: '/' });
                }
            } else if (to.path == "/VerifyEmail"
                || to.path == "/ResetPassword") {
                if (to.query.verify_code !== undefined) {
                    next();
                } else {
                    next({ path: '/Login' });
                }
            } else {
                if (!get_token()) {
                    next({ path: '/Login' });
                } else {
                    if (to.path == '/Login' || to.path == '/login') {
                        next({ path: '/' });
                    } else {
                        next();
                    }
                }
            }
        })
        onUnmounted(() => {
            unwatch();
        })
    }
})
