
import { defineComponent } from 'vue';
import { get_sidebar_list } from '../api/auth';

export default defineComponent({
    data() {
        return {
            default_show: false,
            sidebar_list: [],
        }
    },
    created() {
        get_sidebar_list().then(response => {
            if (response.data.success === true) {
                this.sidebar_list = response.data.sidebar_list;
                if (window.innerWidth >= 768) {
                    this.default_show = true;
                }
            }
        });
    }
})
