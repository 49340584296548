import request from '../utils/request'

export function login(data: JSON) {
    return request({
        url: '/auth/login',
        method: 'post',
        data: data
    })
}

export function logout() {
    return request({
        url: '/auth/logout',
        method: 'post',
    })
}

export function get_user_role() {
    return request({
        url: '/auth/getUserRole',
        method: 'post',
    })
}

export function get_sidebar_list() {
    return request({
        url: '/auth/getSidebarList',
        method: 'post',
    })
}

export function get_user_info() {
    return request({
        url: '/auth/getUserInfo',
        method: 'post',
    })
}

export function register(data: JSON) {
    return request({
        url: '/auth/register',
        method: 'post',
        data: data
    })
}

export function modify_password(data: JSON) {
    return request({
        url: '/auth/modifyPassword',
        method: 'post',
        data: data
    })
}

export function verify_email(verify_code: string) {
    return request({
        url: '/auth/verifyEmail?verify_code=' + verify_code,
        method: 'post',
    })
}

export function resend_verification_mail(data: JSON) {
    return request({
        url: '/auth/resendVerificationMail',
        method: 'post',
        data: data
    })
}

export function forget_password(data: JSON) {
    return request({
        url: '/auth/forgetPassword',
        method: 'post',
        data: data
    })
}

export function reset_password(data: JSON, verify_code: string) {
    return request({
        url: '/auth/resetPassword?verify_code=' + verify_code,
        method: 'post',
        data: data
    })
}