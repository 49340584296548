import { createStore } from "vuex";
import getters from './getters'
import auth from "./modules/auth";

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {auth},
    getters
});
