import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css"
// import axios from 'axios'

createApp(App).use(store).use(router).mount("#app");

// Axios 提供 this.$http 功能。
// app.provide('$axios', axios);
