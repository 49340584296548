<template>
    <header class="bg-dark bg-gradient">
        <div class="d-grid gap-3" style="grid-template-columns: 2fr 2fr;">
            <div class="d-flex">
                <a href="#" class="p-1 text-white" data-bs-target="#sidebar" data-bs-toggle="collapse" aria-expanded="true">
                    <i class="bi bi-list bi-lg p-1 h2"></i>
                </a>
                 <span class="d-none d-sm-inline text-white text-start text-decoration-none align-self-center">後台系統</span>
            </div>
           
            <div class="d-flex">
                <div class="w-100 me-3"></div>
                <div class="flex-shrink-0 dropdown text-white">
                    <div class="d-flex">
                        <a href="#" class="text-decoration-none dropdown-toggle text-white p-1" id="dropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-person-circle bi-lg p-1 h2"></i>
                            <span class="d-none d-sm-inline mx-1 p-1 align-self-center">{{ user }}</span>
                        </a>
                        <ul class="dropdown-menu text-small shadow dropdown-menu-dark" aria-labelledby="dropdownUser">
                            <li><router-link class="dropdown-item" to="/ModifyPassword">修改密碼</router-link></li>
                            <li><a class="dropdown-item" v-on:click="user_logout">登出</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
    name: "Header",
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
    },
    computed: {
        ...mapGetters({
            user: 'user'
        }),
    },
}
</script>

<style>
.dropdown-toggle::after {
    vertical-align: middle;
}
.bi::before, [class^="bi-"]::before {
    vertical-align: middle;
}

.bi {
  vertical-align: -.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle {
    outline: 0;
}

</style>