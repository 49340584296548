import Cookies from 'js-cookie'

const token_key = 'App-Token'

export function get_token() {
  return Cookies.get(token_key)
}

export function set_token(token: any) {
  return Cookies.set(token_key, token)
}

export function remove_token() {
  return Cookies.remove(token_key)
}

const bearer_key = 'bearer-token'

export function get_bearer_token() {
  return Cookies.get(bearer_key)
}

export function set_bearer_token(token: any) {
  return Cookies.set(bearer_key, token)
}

export function remove_bearer_token() {
  return Cookies.remove(bearer_key)
}
