<template>
    <div id="page">
        <div class="page">
            <div class="container-fluid">
                <div class="row flex-nowrap">
                    <Sidebar />
                    <div class="col px-0">
                        <Header />
                        <div class="scrollarea" style="max-height: calc(100vh);">
                            <div style="min-height: calc(100vh - 6rem);">
                                <router-view :key="$route.fullPath"/>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import Sidebar from "../components/Sidebar.vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import { mapActions } from 'vuex';

@Options({
    components: {
        Sidebar,
        Header,
        Footer
    },
    methods: {
        ...mapActions([
            'auth/GetUserInfo',
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
    },
    created() {
        this['auth/GetUserInfo']().then(response => {
            if (response.data.success === false && response.data.check === false) {
                this.user_logout();
            }
        })
    }
})
export default class Home extends Vue {}
</script>

<style lang="scss">
#page {
    // padding-left: 290px;
    transition: 0.3s ease;
    // display: flex;
    flex-wrap: nowrap;
    height: 100vh;
    height: -webkit-fill-available;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
}
</style>